import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import useSWRV from "swrv";
import { getData } from "@/services/axios";

import { inject, computed, ref } from 'vue';
import { isAuthenticatedKey, allSchoolListKey } from '/codebuild/output/src3265658254/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'ForumSelectDetailSchools',
  props: {
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    selectGradeIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    }
  },
  emits: ["handleClickNext", "toggleIsShowModal"],
  setup(__props, {
    emit
  }) {
    const props = __props;

    // @ts-check

    const isAuthenticated = inject(isAuthenticatedKey);
    const allSchoolList = inject(allSchoolListKey);
    const {
      data: schoolWithGrade
    } = useSWRV(() => isAuthenticated.value && "school-grade", async () => {
      /**
       * @type {{
       *   items: {
       *     id: number;
       *     short_name: string | null;
       *     name: string;
       *     grade: { id: number; school_id: number; name: string }[];
       *   }[];
       * }}
       */
      const result = await getData("school", {
        with: "grade"
      });
      return result.items;
    }, {
      revalidateOnFocus: false,
      dedupingInterval: Infinity
    });
    const selectSchoolList = computed(() => {
      let temp = schoolWithGrade.value ?? [];
      temp = temp.filter(v => props.selectSchoolIdList.includes(v.id));
      return temp;
    });
    const searchWord = ref("");
    const isShowChecked = ref(false);
    const tempSelectIdList = ref(structuredClone(props.selectGradeIdList));
    const childRef = ref();
    const isAllSelect = computed(() => childRef.value?.every(child => child.isAllGradeSelect()));
    const handleAllSelect = () => {
      if (isAllSelect.value) {
        tempSelectIdList.value = [];
      } else {
        tempSelectIdList.value = childRef.value.flatMap(child => child.getShowGradeIdList());
      }
    };
    const handleClickNext = () => {
      // 学年を選択された学校のみを選択済みにする
      const ids = schoolWithGrade.value.filter(v => props.selectSchoolIdList.includes(v.id) && v.grade.some(v2 => tempSelectIdList.value.includes(v2.id))).map(v => v.id);
      emit("handleClickNext", ids, tempSelectIdList.value);
    };
    return {
      __sfc: true,
      props,
      emit,
      isAuthenticated,
      allSchoolList,
      schoolWithGrade,
      selectSchoolList,
      searchWord,
      isShowChecked,
      tempSelectIdList,
      childRef,
      isAllSelect,
      handleAllSelect,
      handleClickNext
    };
  }
};