import useSWRV from "swrv";
import { getData, createData } from "@/services/axios";
import { cdate } from "@/utils/cdate";

import { useRoute, useRouter } from 'vue-router/composables';
import { inject, watch, computed, ref } from 'vue';
import { isAuthenticatedKey, meKey } from '/codebuild/output/src3265658254/src/cocoo_gov/src/utils/injectionKey.js';
import { useToggle } from '@vueuse/core';
import { useCustomTitle } from '/codebuild/output/src3265658254/src/cocoo_gov/src/composables/useCustomTitle.js';
export default {
  __name: 'ForumEdit',
  setup(__props) {
    // @ts-check
    const route = useRoute();
    const router = useRouter();
    const isAuthenticated = inject(isAuthenticatedKey);
    const me = inject(meKey);
    watch([me, isAuthenticated], () => {
      // 投稿者以外の場合は Dashboard に戻す
      if (isAuthenticated.value && !me.value?.allow?.forum_post) {
        router.push({
          name: "Dashboard"
        });
      }
    }, {
      immediate: true
    });
    const isCreate = computed(() => route.name === "ForumCreate");
    const pageTitle = computed(() => isCreate.value ? "記事作成" : "記事編集");
    const [isShowModal, toggleIsShowModal] = useToggle();
    const [isShowConfirmModal, toggleIsShowConfirmModal] = useToggle(false);
    const {
      data: editBaseData
    } = useSWRV(() => `forum/${route.params.id}`, async () => {
      if (route.params.id) {
        const result = await getData(`government_forum/${route.params.id}`);
        return result.items;
      } else {
        return {};
      }
    }, {
      revalidateOnFocus: false
    });
    watch([editBaseData], () => {
      // 送信済みの場合は Dashboard に戻す
      if (["sent", "failed"].includes(editBaseData.value?.email_status)) {
        router.push({
          name: "Dashboard"
        });
      }
    }, {
      immediate: true
    });

    /**
     * @returns {{
     *   target: "school" | "guardian";
     *   title: string;
     *   message: string;
     *   expire_on: string;
     *   email_status: string;
     *   is_important: boolean;
     *   school_ids: number[];
     * }}
     */
    const setEditData = () => {
      return {
        target: editBaseData.value?.target || route.params?.target || "school",
        title: editBaseData.value?.title || route.params?.title || "",
        message: editBaseData.value?.message || route.params?.message || "",
        expire_on: editBaseData.value?.expire_on || route.params?.expire_on || cdate().add(9, "month").get("year") + "-05-31",
        email_status: editBaseData.value?.email_status || route.params?.email_status || "not_sent",
        is_important: editBaseData.value?.is_important || route.params?.is_important || false,
        // 送信先は複製対象外
        school_ids: editBaseData.value?.school?.map(v => v.id) || []
      };
    };
    const editData = ref(setEditData());
    const useEmail = ref(editData.value?.email_status !== "not_sent");

    /** @returns {{ grade_ids: number[] }} */
    const setEditOnlyGuardianData = () => {
      return {
        grade_ids: editBaseData.value?.school?.flatMap(v => JSON.parse(v.pivot.grade_ids)) ||
        // @ts-ignore
        route.params?.school?.flatMap(v => JSON.parse(v.pivot.grade_ids)) || []
      };
    };
    const editOnlyGuardianData = ref(setEditOnlyGuardianData());
    const setAttachmentList = () => {
      return editBaseData.value?.attachment?.length ? editBaseData.value.attachment : [undefined];
    };

    /** @type {Ref<File[] | any[]>} */
    const attachmentList = ref(setAttachmentList());
    watch(editBaseData, () => {
      editData.value = setEditData();
      editOnlyGuardianData.value = setEditOnlyGuardianData();
      attachmentList.value = setAttachmentList();
    });
    const updeteSchoolIds = input => {
      editData.value.school_ids = input;
    };
    const updeteGradeIds = input => {
      editOnlyGuardianData.value.grade_ids = input;
    };
    const [isShowCancelConfirmModal, toggleIsShowCancelConfirmModal] = useToggle();
    const removeSelectSchool = () => {
      editData.value.school_ids = [];
      editOnlyGuardianData.value.grade_ids = [];
      toggleIsShowCancelConfirmModal(false);
    };
    const modalMessage = ref({
      title: "",
      message: ""
    });
    const [isShowMessageModal, toggleIsShowMessageModal] = useToggle();
    const closeMessageModal = () => {
      toggleIsShowMessageModal(false);
      router.push({
        name: "ForumIndex"
      });
    };
    const resetError = () => {
      return {
        school_ids: "",
        title: "",
        message: ""
      };
    };
    const error = ref(resetError());
    const validateSchoolIds = () => {
      let valid = true;
      error.value.school_ids = "";
      if (!editData.value.school_ids.length) {
        error.value.school_ids = "送信先を入力してください。";
        valid = false;
      }
      return valid;
    };
    const validateTitle = () => {
      let valid = true;
      error.value.title = "";
      if (!editData.value.title) {
        error.value.title = "記事のタイトルを入力してください。";
        valid = false;
      }
      return valid;
    };
    const validateMessage = () => {
      let valid = true;
      error.value.message = "";
      if (!editData.value.message) {
        error.value.message = "メッセージ本文を入力してください。";
        valid = false;
      }
      return valid;
    };
    const validateForum = () => {
      error.value = resetError();
      return !(!validateSchoolIds() && !validateTitle() && !validateMessage());
    };
    const [isDelete, toggleIsDelete] = useToggle();
    const handleMailPreview = () => {
      if (useEmail.value) {
        validateForum() ? toggleIsShowConfirmModal(true) : false;
      } else {
        createEditForum();
      }
    };
    const createEditForum = async () => {
      if (!validateForum()) {
        return false;
      }

      /**
       * @type {typeof editData.value & { email_status: string } & Partial<typeof editOnlyGuardianData.value> & {
       *     attachment?: File;
       *     file_name?: string;
       *     delete?: boolean;
       *   }}
       */
      let payload = {
        ...editData.value
      };
      if (editData.value.email_status === "not_sent") {
        // メール送信未使用のときのみ、送信に切り替えることを許可
        payload = {
          ...payload,
          email_status: useEmail.value ? "pending" : "not_sent"
        };
      }
      if (editData.value.target === "guardian") {
        payload = {
          ...payload,
          ...editOnlyGuardianData.value
        };
      }
      if (attachmentList.value[0] && attachmentList.value[0] instanceof File) {
        payload = {
          ...payload,
          attachment: attachmentList.value[0],
          file_name: attachmentList.value[0].name
        };
      } else if (isDelete.value) {
        payload = {
          ...payload,
          delete: isDelete.value
        };
      }
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(v2 => {
            formData.append(key + "[]", String(v2));
          });
        } else if (typeof value === "boolean") {
          formData.append(key, String(Number(value)));
        } else {
          formData.append(key, value);
        }
      });
      try {
        if (isCreate.value) {
          await createData("government_forum", formData);
          modalMessage.value = {
            title: "投稿完了",
            message: "記事の投稿が完了しました"
          };
        } else {
          // 素のputではformdataが使えなかったのでmethodを指定しつつpostする
          formData.append("_method", "put");
          await createData(`government_forum/${route.params.id}`, formData);
          modalMessage.value = {
            title: "編集完了",
            message: "記事を編集しました"
          };
        }
        toggleIsShowMessageModal(true);
      } catch (error) {
        console.log(error);
      }
    };
    useCustomTitle(pageTitle);
    return {
      __sfc: true,
      route,
      router,
      isAuthenticated,
      me,
      isCreate,
      pageTitle,
      isShowModal,
      toggleIsShowModal,
      isShowConfirmModal,
      toggleIsShowConfirmModal,
      editBaseData,
      setEditData,
      editData,
      useEmail,
      setEditOnlyGuardianData,
      editOnlyGuardianData,
      setAttachmentList,
      attachmentList,
      updeteSchoolIds,
      updeteGradeIds,
      isShowCancelConfirmModal,
      toggleIsShowCancelConfirmModal,
      removeSelectSchool,
      modalMessage,
      isShowMessageModal,
      toggleIsShowMessageModal,
      closeMessageModal,
      resetError,
      error,
      validateSchoolIds,
      validateTitle,
      validateMessage,
      validateForum,
      isDelete,
      toggleIsDelete,
      handleMailPreview,
      createEditForum,
      cdate
    };
  }
};