import useSWRV from "swrv";
import { getData } from "@/services/axios";

import { inject, ref, watch } from 'vue';
import { isAuthenticatedKey, meKey } from '/codebuild/output/src3265658254/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'ForumIndex',
  setup(__props) {
    // @ts-check
    const isAuthenticated = inject(isAuthenticatedKey);
    const me = inject(meKey);
    const payload = ref({
      target: null
    });
    const {
      data: forumList,
      mutate
    } = useSWRV(() => isAuthenticated.value && "forum", async () => {
      const result = await getData("government_forum", payload.value);
      return result.items;
    }, {
      refreshInterval: 0,
      revalidateOnFocus: false
    });
    watch(() => payload.value.target, () => {
      mutate();
    });
    return {
      __sfc: true,
      isAuthenticated,
      me,
      payload,
      forumList,
      mutate
    };
  }
};