var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main page_students_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("職員情報" + _vm._s(_setup.isCreate ? "作成" : "更新"))]), !_setup.isCreate && !_setup.staffBaseData || _setup.isValidating ? _c('LoadingMessage') : _vm._e(), _setup.isLoading.postData ? _c('LoadingMessage', {
    attrs: {
      "loading-message": _setup.loadingMessage
    }
  }) : _vm._e(), _setup.staff ? _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('section', {
    staticClass: "gray_frame"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("職員の設定")]), _c('div', {
    staticClass: "border_frame"
  }, [_c('RowItem', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("職員氏名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.staff.name,
      expression: "staff.name"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "山田 花子"
    },
    domProps: {
      "value": _setup.staff.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.staff, "name", $event.target.value);
      }
    }
  }), _setup.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.name) + " ")]) : _vm._e()])]), _c('RowItem', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("部署")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.staff.department,
      expression: "staff.department"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "教育委員会（投稿者氏名として表示されます）"
    },
    domProps: {
      "value": _setup.staff.department
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.staff, "department", $event.target.value);
      }
    }
  }), _setup.error.department ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.department) + " ")]) : _vm._e()])]), _c('RowItem', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("メールアドレス")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.staff.email,
      expression: "staff.email"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "sample@example.com"
    },
    domProps: {
      "value": _setup.staff.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.staff, "email", $event.target.value);
      }
    }
  }), _setup.error.email ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.email) + " ")]) : _vm._e()])]), _setup.me.allow?.admin && _setup.staff.id !== _setup.me.id ? _c('RowItem', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("権限")]), _c('div', {
    staticClass: "col_9 radio-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.staff.admin,
      expression: "staff.admin"
    }],
    attrs: {
      "id": "authorityManager",
      "type": "radio",
      "name": "authority"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_setup.staff.admin, true)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.staff, "admin", true);
      }
    }
  }), _c('label', {
    attrs: {
      "for": "authorityManager"
    }
  }, [_vm._v("管理者")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.staff.admin,
      expression: "staff.admin"
    }],
    attrs: {
      "id": "authorityGeneral",
      "type": "radio",
      "name": "authority"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_setup.staff.admin, false)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.staff, "admin", false);
      }
    }
  }), _c('label', {
    attrs: {
      "for": "authorityGeneral"
    }
  }, [_vm._v("一般")]), _setup.error.email ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.error.email) + " ")]) : _vm._e()])]) : _vm._e(), _setup.me.allow?.admin && _setup.staff.id !== _setup.me.id ? _c('RowItem', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("掲示板投稿")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.staff.forum_post,
      expression: "staff.forum_post"
    }],
    attrs: {
      "id": "forum",
      "type": "checkbox",
      "name": "forum"
    },
    domProps: {
      "checked": Array.isArray(_setup.staff.forum_post) ? _vm._i(_setup.staff.forum_post, null) > -1 : _setup.staff.forum_post
    },
    on: {
      "change": function ($event) {
        var $$a = _setup.staff.forum_post,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_setup.staff, "forum_post", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_setup.staff, "forum_post", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_setup.staff, "forum_post", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "forum"
    }
  }, [_vm._v("投稿者")])])]) : _vm._e()], 1)]), _c('section', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "キャンセル"
    },
    on: {
      "click": function ($event) {
        return _setup.router.go(-1);
      }
    }
  }), _setup.isCreate ? _c('input', {
    staticClass: "register_button",
    attrs: {
      "type": "button",
      "value": "登録する"
    },
    on: {
      "click": _setup.saveData
    }
  }) : _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "更新する"
    },
    on: {
      "click": _setup.saveData
    }
  }), !_setup.isCreate && Number(_setup.me.id) !== Number(_setup.route.params.id) ? _c('button', {
    staticClass: "delete_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.confirmModalDialog.reveal();
      }
    }
  }, [_vm._v(" 削除する ")]) : _vm._e()])]) : _vm._e(), _setup.isModalRevealed ? _c('MessageModal', {
    on: {
      "close": function ($event) {
        return _setup.modalDialog.confirm();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_setup.messageSettings.title))];
      },
      proxy: true
    }, {
      key: "message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_setup.messageSettings.message) + " ")];
      },
      proxy: true
    }], null, false, 2741950409)
  }) : _vm._e(), _setup.isConfirmModalRevealed ? _c('ConfirmModal', {
    on: {
      "clickOK": function ($event) {
        return _setup.confirmModalDialog.confirm();
      },
      "clickCancel": function ($event) {
        return _setup.confirmModalDialog.cancel();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("職員情報削除")];
      },
      proxy: true
    }, {
      key: "message",
      fn: function () {
        return [_c('p', [_vm._v("職員情報を削除します。")]), _c('p', [_vm._v("本当に実行してもよろしいですか？")])];
      },
      proxy: true
    }], null, false, 1641628718)
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };