import useSWRV from "swrv";
import { getData } from "@/services/axios";
import { cdate } from "@/utils/cdate";

import { inject, ref, watch } from 'vue';
import { isAuthenticatedKey } from '/codebuild/output/src3265658254/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'ForumMailIndex',
  setup(__props) {
    // @ts-check
    const isAuthenticated = inject(isAuthenticatedKey);
    const currentFiscalYear = cdate().add(-3, "month").get("year");
    const threeYearsAgo = cdate().add(-3, "month").add(-3, "year").get("year");
    const fiscalYears = [];
    let i;
    for (i = threeYearsAgo; i <= currentFiscalYear; i++) {
      if (i === currentFiscalYear) {
        fiscalYears.push({
          value: currentFiscalYear,
          label: "今年度"
        });
      } else {
        fiscalYears.push({
          value: i,
          label: i + "年度"
        });
      }
    }
    const payload = ref({
      target: null,
      use_email: true
    });
    const filter = ref({
      title: "",
      date: "",
      fiscalYear: currentFiscalYear
    });
    const resetFilter = () => {
      filter.value = {
        title: "",
        date: "",
        fiscalYear: currentFiscalYear
      };
      handleFilter();
    };
    const {
      data: forumList,
      mutate
    } = useSWRV(() => isAuthenticated.value && "forum", async () => {
      const result = await getData("government_forum", payload.value);
      result.items.last_fetched_at = cdate().format("YYYY年MM月DD日 HH時mm分");
      return result.items;
    }, {
      refreshInterval: 60000,
      revalidateOnFocus: false
    });
    const filteredForumList = ref([]);
    const handleFilter = () => {
      console.log(filter.value);
      let f = Array.isArray(forumList.value) && forumList.value.slice();
      if (filter.value.title) {
        f = Array.isArray(f) && f.filter(v => v.title.includes(filter.value.title));
      }
      if (filter.value.date) {
        f = Array.isArray(f) && f.filter(v => v.created_at.startsWith(filter.value.date));
      }
      if (filter.value.fiscalYear) {
        f = Array.isArray(f) && f.filter(v => cdate(v.created_at) >= cdate(`${filter.value.fiscalYear}-04-01`) && cdate(v.created_at) < cdate(`${filter.value.fiscalYear + 1}-04-01`));
      }
      filteredForumList.value = f;
    };
    watch(() => forumList.value, () => {
      handleFilter();
    });
    const emailStatus = status => {
      switch (status) {
        case "sent":
          return "送信完了";
        case "pending":
          return "送信待ち";
        case "sending":
          return "送信中";
        case "not_sent":
          return "メール送信なし";
        case "failed":
          return "送信失敗";
        default:
          return "不明";
      }
    };
    watch(() => payload.value.target, () => {
      mutate();
    });
    return {
      __sfc: true,
      isAuthenticated,
      currentFiscalYear,
      threeYearsAgo,
      fiscalYears,
      i,
      payload,
      filter,
      resetFilter,
      forumList,
      mutate,
      filteredForumList,
      handleFilter,
      emailStatus,
      cdate
    };
  }
};