import { inject, ref, computed } from 'vue';
import { allSchoolListKey, isHaveSchoolTypeKey } from '/codebuild/output/src3265658254/src/cocoo_gov/src/utils/injectionKey.js';
import { useToggle } from '@vueuse/core';
export default {
  __name: 'ForumSelectSchools',
  props: {
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  },
  emits: ["handleClickNext", "updeteSchoolIds", "toggleIsShowModal"],
  setup(__props, {
    emit
  }) {
    const props = __props;

    // @ts-check

    const allSchoolList = inject(allSchoolListKey);
    const isHaveSchoolType = inject(isHaveSchoolTypeKey);
    const selectSchoolType = ref("");
    const [isShowChecked, toggleIsShowChecked] = useToggle(false);

    /** 表示する学校リストを作成する */
    const showSchoolList = computed(() => {
      if (isShowChecked.value && selectSchoolType.value) {
        return allSchoolList.value.filter(v => v.type === selectSchoolType.value && tempSelectIdList.value.includes(v.id));
      } else if (isShowChecked.value) {
        return allSchoolList.value.filter(v => tempSelectIdList.value.includes(v.id));
      } else if (selectSchoolType.value) {
        return allSchoolList.value.filter(v => v.type === selectSchoolType.value);
      } else {
        return allSchoolList.value;
      }
    });
    const tempSelectIdList = ref(props.selectSchoolIdList);
    const isAllSelect = computed(() => showSchoolList.value.every(v => tempSelectIdList.value.includes(v.id)));
    const handleAllSelect = () => {
      if (isAllSelect.value) {
        tempSelectIdList.value = [];
      } else {
        tempSelectIdList.value = showSchoolList.value.map(v => v.id);
      }
    };
    const handleClickNext = () => {
      emit("handleClickNext", tempSelectIdList.value);
    };
    return {
      __sfc: true,
      props,
      emit,
      allSchoolList,
      isHaveSchoolType,
      selectSchoolType,
      isShowChecked,
      toggleIsShowChecked,
      showSchoolList,
      tempSelectIdList,
      isAllSelect,
      handleAllSelect,
      handleClickNext
    };
  }
};